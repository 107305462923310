



import { Component, Vue, Emit } from 'vue-property-decorator';
import { component } from 'vue/types/umd';
import { Route } from 'vue-router';
import StepNavi from '@/components/contents/stepNavi/StepNavi.vue';
import store from '@/store';
import CourseBox from '@/components/contents/course/CourseBox.vue';
import { CourseResponseCourses, CoursePostOutlineOutline } from '../../../../../types/typescript-axios/api';

Component.registerHooks([
  'beforeRouteEnter',
]);

@Component({
  components: {
    StepNavi,
    CourseBox,
  },
})
export default class CourseDetailComplete extends Vue {
  private get stepNavi(): string {
    const $parent: any = this.$parent;
    return $parent.stepNavi;
  }

  private get courseDetail(): CourseResponseCourses {
    const $parent: any = this.$parent;
    return $parent.courseDetail;
  }
  private get title(): any {
    const $parent: any = this.$parent;
    return $parent.title;
  }

  private get outline(): CoursePostOutlineOutline[] {
    const result: CoursePostOutlineOutline[] = [...this.courseDetail.data2.outline];
    result.unshift({
      header: '講座名',
      data: this.courseDetail.data2.title.rendered,
    });
    return result;
  }

  private paramsName: any = '';

  private async beforeRouteEnter(to: Route, from: Route, next: any) {
    store.commit(
      'window/setTitle',
      `申込み完了 ${store.getters['window/isTitle']} 講座 ` + process.env.VUE_APP_TITLE_SUFFIX,
    );
    next();
  }

  private mounted() {
    this.paramsName = this.$route.params.courceId;
  }

  @Emit()
  private dateFilter(date: string) {
    const d: Date = new Date(date);
    const dayArr = ['日', '月', '火', '水', '木', '金', '土'];
    const day = dayArr[d.getDay()];
    return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日（${day}）`;
  }
  @Emit()
  private brReplace(item: string) {
    let result = '';
    result = item.replace( /\n|\r\n|\r/g, '<br>' );
    return result;
  }
}
